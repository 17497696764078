import { addPropertyControls, ControlType } from "framer"
import { useState } from "react"

export function CalendlyForm(props) {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [businessEmail, setBusinessEmail] = useState("")
    const [companyName, setCompanyName] = useState("")
    const [somethingElse, setSomethingElse] = useState(false)
    const [somethingElseText, setSomethingElseText] = useState("")
    const [additionalComments, setAdditionalComments] = useState("")

    const [monitoring, setMonitoring] = useState(false)
    const [strategy, setStrategy] = useState(false)
    const [confidence, setConfidence] = useState(false)

    const handleSubmit = (event) => {
        event.preventDefault()

        // Validate business email (simple check for non-free domains)
        const freeEmailDomains = [
            "gmail.com",
            "yahoo.com",
            "hotmail.com",
            "outlook.com",
        ]
        const emailDomain = businessEmail.split("@")[1]

        if (!emailDomain || freeEmailDomains.includes(emailDomain)) {
            alert("Please use a business email address.")
            return
        }

        // Construct the Calendly URL with the captured data
        const calendlyUrl = `https://calendly.com/guy-5/wonder-demo?email=${businessEmail}&first_name=${firstName}&last_name=${lastName}&company_name=${companyName}`

        // Open the Calendly URL in a new tab
        window.open(calendlyUrl, "_blank")
    }

    return (
        <div
            style={{
                backgroundColor: props.backgroundColor,
                padding: "20px",
                borderRadius: props.borderRadius,
            }}
        >
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    placeholder="First Name"
                    value={firstName}
                    onChange={(event) => setFirstName(event.target.value)}
                    required
                    style={{
                        padding: "10px",
                        fontSize: props.fontSize,
                        color: props.fontColor,
                        borderRadius: props.borderRadius,
                    }}
                />
                <input
                    type="text"
                    placeholder="Last Name"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                    required
                    style={{
                        padding: "10px",
                        fontSize: props.fontSize,
                        color: props.fontColor,
                        borderRadius: props.borderRadius,
                    }}
                />
                <input
                    type="email"
                    placeholder="Business Email"
                    value={businessEmail}
                    onChange={(event) => setBusinessEmail(event.target.value)}
                    required
                    style={{
                        padding: "10px",
                        fontSize: props.fontSize,
                        color: props.fontColor,
                        borderRadius: props.borderRadius,
                    }}
                />
                <input
                    type="text"
                    placeholder="Company Name"
                    value={companyName}
                    onChange={(event) => setCompanyName(event.target.value)}
                    required
                    style={{
                        padding: "10px",
                        fontSize: props.fontSize,
                        color: props.fontColor,
                        borderRadius: props.borderRadius,
                    }}
                />

                <label>
                    <input
                        type="checkbox"
                        checked={monitoring}
                        onChange={() => setMonitoring(!monitoring)}
                    />
                    Monitoring threats and opportunities
                </label>

                <label>
                    <input
                        type="checkbox"
                        checked={strategy}
                        onChange={() => setStrategy(!strategy)}
                    />
                    Delivering on internal strategy projects
                </label>

                <label>
                    <input
                        type="checkbox"
                        checked={confidence}
                        onChange={() => setConfidence(!confidence)}
                    />
                    Building plans with confidence
                </label>

                <label>
                    <input
                        type="checkbox"
                        checked={somethingElse}
                        onChange={() => setSomethingElse(!somethingElse)}
                    />
                    Something Else
                </label>

                {somethingElse && (
                    <input
                        type="text"
                        placeholder="Please specify"
                        value={somethingElseText}
                        onChange={(event) =>
                            setSomethingElseText(event.target.value)
                        }
                        style={{
                            padding: "10px",
                            fontSize: props.fontSize,
                            color: props.fontColor,
                            borderRadius: props.borderRadius,
                        }}
                    />
                )}

                <textarea
                    placeholder="Anything else you'd like to share?"
                    value={additionalComments}
                    onChange={(event) =>
                        setAdditionalComments(event.target.value)
                    }
                    style={{
                        padding: "10px",
                        fontSize: props.fontSize,
                        color: props.fontColor,
                        borderRadius: props.borderRadius,
                    }}
                ></textarea>

                <button
                    type="submit"
                    style={{
                        backgroundColor: props.buttonColor,
                        padding: "10px 20px",
                        fontSize: props.fontSize,
                        color: props.fontColor,
                        borderRadius: props.borderRadius,
                    }}
                >
                    {props.buttonText}
                </button>
            </form>
        </div>
    )
}

// Expose property controls in Framer UI
addPropertyControls(CalendlyForm, {
    backgroundColor: {
        title: "Background Color",
        type: ControlType.Color,
        defaultValue: "#FFFFFF",
    },
    buttonColor: {
        title: "Button Color",
        type: ControlType.Color,
        defaultValue: "#0099FF",
    },
    fontSize: {
        title: "Font Size",
        type: ControlType.Number,
        defaultValue: 16,
        min: 10,
        max: 30,
    },
    fontColor: {
        title: "Font Color",
        type: ControlType.Color,
        defaultValue: "#000000",
    },
    borderRadius: {
        title: "Border Radius",
        type: ControlType.Number,
        defaultValue: 5,
        min: 0,
        max: 50,
    },
    buttonText: {
        title: "Button Text",
        type: ControlType.String,
        defaultValue: "Schedule a Meeting",
    },
})
